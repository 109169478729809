import React from "react";
import { Tooltip } from "@softura/fluentui-basiccomponents";
interface IProps {
    content?:string,
    id?:any,
    tooltipProps?:any,
    children:any
    directionalHint?:any
}
const TooltipComponent = (props:IProps) => {
    return <Tooltip  
    content={props.content}
    id={props.id}
    tooltipProps={props.tooltipProps}
    directionalHint={props.directionalHint}
    >
        {props.children}
    </Tooltip>
}

export default TooltipComponent