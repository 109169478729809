import React, {useState, useEffect} from 'react';
import SupplierEntry from './SupplierEntry';
import { useNavigate } from 'react-router-dom';
import supplierEntrySelectors from '../../../../state/features/RebateManagement/SupplierEntry/selectors';
import supplierEntryOperations from '../../../../state/features/RebateManagement/SupplierEntry/operations';
import navOperations from '../../../../state/features/Navigation/operations';
import navSelectors from '../../../../state/features/Navigation/selectors';
import userSelector from '../../../../state/features/UserProfile/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {  isAuthorized } from 'client/utils/Permissions';
import ErrorHandling from '../../../CommonComponents/ErrorHandling/ErrorHandling'
import { useDebounce } from 'client/CommonComponents/CustomDebounce/DebounceHook';

interface Iprops {
    showSupplier?:boolean,
    hideSupplierModal?:Function,
    showAddNew?:boolean
}
const SupplierEntryContainer = (props:Iprops) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showSupplierModal, setShowSupplierModal] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [values, setValues] = useState<any>({
        isActive: true
    });
    const [isSaveClicked, setSaveClicked] = useState(false);
    const [stateOptions, setStateOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState<any>([]);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [isNew, setIsNew] = useState(false);
    const [categoryManagerOptions, setCategoryManagerOptions] = useState<any>([]);
    const dispatchGetState = supplierEntryOperations.dispatchGetStates(dispatch);
    const dispatchGetCountries = supplierEntryOperations.dispatchGetCountries(dispatch);
    const dispatchGetSupplierdata = supplierEntryOperations.dispatchGetSupplierdata(dispatch);
    const dispatchGetSupplierById = supplierEntryOperations.dispatchGetSupplierById(dispatch);
    const dispatchGetCreateUpdateSupplier = supplierEntryOperations.dispatchGetCreateUpdateSupplier(dispatch);
    const dispatchSetSupplierSavedFlag = supplierEntryOperations.dispatchSetSupplierSavedFlag(dispatch);
    const dispatchSetSupplier = supplierEntryOperations.dispatchSetSupplier(dispatch);
    const dispatchSetErrorSupplier = supplierEntryOperations.dispatchSetErrorSupplier(dispatch);
    const dispatchSetSupplierName = supplierEntryOperations.dispatchSetSupplierName(dispatch);
    const dispatchNavVendorEntry = navOperations.dispatchNavVendorEntry(dispatch);
    const dispatchGetCategoryManager = supplierEntryOperations.dispatchGetCategoryManager(dispatch);
    const dispatchGetPrimaryAbnumber = supplierEntryOperations.dispatchGetPrimaryAbnumber(dispatch);
    const dispatchGetSecondaryAbnumber = supplierEntryOperations.dispatchGetSecondaryAbnumber(dispatch);
    const dispatchGetSupplierDetailsByAbnumber = supplierEntryOperations.dispatchGetSupplierDetailsByAbnumber(dispatch);
    const dispatchSetSupplierDetailsByAbnumber = supplierEntryOperations.dispatchSetSupplierDetailsByAbnumber(dispatch);
    const dispatchGetSecondaryAbnumberBySupplier = supplierEntryOperations.dispatchGetSecondaryAbnumberBySupplier(dispatch);
    const [AdditionalAbnoSelected, setAdditionalAbnoSelected] = useState<any>([]);
    const [primaryAbnumberOptions, setPrimaryAbnumberOptions] = useState([]);
    const [secondaryAbnumberOptions, setSecondaryAbnumberOptions] = useState([]);
    const [isAddNewAbnumber, setIsAddNewAbnumber] = useState(false);
    const [primarySearchValue, setPrimarySearchValue] = useState<any>('');
    const [secondarySearchValue, setSecondarySearchValue] = useState<any>('');
    const [isOptionsSearched, setIsOptionsSearched] = useState<any>(false);
    const [isLoadingPrimary, setIsLoadingPrimary] = useState<any>(false);
    const [isLoadingSecondary, setIsLoadingSecondary] = useState<any>(false);
    const [isNoAbNumber, setIsNoAbNumber] = useState<any>(false);

    const  {
        stateDropdown,
        countryDropdown,
        suppliersData,
        supplier,
        supplierSaved,
        errorSupplier,
        username,
        navVendorEntry,
        categoryManagerdata,
        primaryAbnumber,
        secondaryAbnumber,
        supplierDetailsByAbnumber,
        secondaryAbnumberBySupplier
    }:any  = useSelector(
        (state:any) => ({
            stateDropdown: supplierEntrySelectors.getStateData(state),
            countryDropdown: supplierEntrySelectors.getCountryData(state),
            suppliersData: supplierEntrySelectors.getSuppliersData(state),
            supplier: supplierEntrySelectors.getSupplier(state),
            supplierSaved: supplierEntrySelectors.getSupplierSaved(state),
            errorSupplier: supplierEntrySelectors.getErrorSupplier(state),
            username: userSelector.getUsername(state),
            navVendorEntry: navSelectors.GetNavVendorEntry(state),
            categoryManagerdata: supplierEntrySelectors.getCategoryManagerData(state),
            primaryAbnumber: supplierEntrySelectors.getPrimaryAbnumber(state),
            secondaryAbnumber: supplierEntrySelectors.getSecondaryAbnumber(state),
            supplierDetailsByAbnumber: supplierEntrySelectors.getSupplierDetailsByAbnumber(state),
            secondaryAbnumberBySupplier: supplierEntrySelectors.getSecondaryAbnumberBySupplier(state)
        })
    )

    const getOptions = (item: any) => ({
        ...item,
        label: item.text,
        value: item.text,
        key: parseInt(item.value)
    })

    const primaryDebounce = useDebounce(primarySearchValue, 500)

    useEffect(()=>{
        setIsLoadingPrimary(true)
        dispatchGetPrimaryAbnumber({
            supplierId: values.supplierId,
            searchParam: primarySearchValue
        });
    },[primaryDebounce])

    const secondaryDebounce = useDebounce(secondarySearchValue, 500)

    useEffect(()=>{
        if(secondaryDebounce?.length > 0){
            setIsOptionsSearched(true)
        }
        setIsLoadingSecondary(true)
        dispatchGetSecondaryAbnumber({
            supplierId: values.supplierId,
            searchParam: secondarySearchValue
        });
    },[secondaryDebounce])

    useEffect(() => {
        setIsLoadingPrimary(false)
        if (primaryAbnumber?.length > 0) {
            const options: any = primaryAbnumber.map((item: any) => (
                {key: item.abNumber, label: item.abNumber + '-' + item.alphaName, value: item.abNumber, ...item}
            ))
            setPrimaryAbnumberOptions(options);
        }
    }, [primaryAbnumber]);

    useEffect(()=>{  
        setIsLoadingSecondary(false)
        if(secondaryAbnumber?.length > 0){
            let options: any = secondaryAbnumber.filter((item: any) => item.abNumber != values?.primaryAbNumber).map((item: any) => (
                {key: item.abNumber, label: item.abNumber + '-' + item.alphaName, value: item.abNumber, ...item}
            ))
            if(AdditionalAbnoSelected?.length > 0 && (secondarySearchValue === '' || secondarySearchValue === null)){
                AdditionalAbnoSelected.map((item:any)=>{
                    options = options.filter((optionItem: any)=>optionItem.abNumber!=item.abNumber)
                })
                options = [...AdditionalAbnoSelected, ...options]
            }
            setSecondaryAbnumberOptions(options);
        }else{
                setSecondaryAbnumberOptions([])
        }
    },[values?.primaryAbNumber, secondaryAbnumber])

    useEffect(() => {
        if (categoryManagerdata?.length > 0) {
            const options: any = categoryManagerdata.map((item: any) => (
                {key: item.categoryManagerId, text: item.categoryManagerName, value: item.categoryManagerName}
            ))
            setCategoryManagerOptions(options);
        }
    }, [categoryManagerdata]);    

    useEffect(() => {
        if (stateDropdown?.length > 0) {
            const options: any = stateDropdown.map((item: any) => (
                getOptions(item)
            ))
            setStateOptions(options);
        }
    }, [stateDropdown]);

    useEffect(() => {
        if (supplierSaved) {
            const element = document.getElementsByClassName("modalBodyContainer");
            element[0]?.scroll(0,0);
            dispatchSetSupplierDetailsByAbnumber({})
            setIsAddNewAbnumber(false)
            setAdditionalAbnoSelected([])
            setIsNoAbNumber(false)
            setValues({
                isActive: true
            })
            dispatchSetSupplier({})
            getInitialError();
            setSaveClicked(false);
            setTimeout(() => {
                closeSupplier()
            }, 2000);
        }
    }, [supplierSaved]);

    useEffect(() => {
        if (countryDropdown?.length > 0) {
            const options: any = countryDropdown.map((item: any) => (
                getOptions(item)
            ))
            setCountryOptions(options);
        }
    }, [countryDropdown]);

    useEffect(() => {
        if (values.countryId) {
            dispatchGetState(values.countryId);
        }
    }, [values.countryId])

    const getInitialError = () => {
        setErrors({
            supplierName: 'Please provide Name',
            addressLine1:'Please provide Address Line 1',
            city:'Please provide City',
            stateId:'Please select State',
            countryId:'Please select Country',
            contactName:'Please provide Contact Name',
            email:'Please provide Email',
            telephone:'Please provide Telephone',
            manufacturer: "Please select Manufacturer",
            categoryManagerError: "Please select Category Manager",
            // additionalAbnumber: "Please select Additional Abnumber",
            isSelfReported: "Please select Self Reported",
            primaryAbNumber: isNoAbNumber ? "" : "Please select Primary AbNumber"
        })
    }    

    useEffect(() => {
        setShowSupplierModal(true);
        dispatchGetCountries();
        dispatchGetSupplierdata();
        dispatchGetCategoryManager();
        if (props.showSupplier && typeof props.showSupplier !== "boolean") {
            dispatchGetSupplierById(props.showSupplier);
        }
        getInitialError()
        dispatchGetPrimaryAbnumber({
            supplierId: 0,
            searchParam: ""
        });
    }, []);

    useEffect(() => {
        if (navVendorEntry) {
            dispatchNavVendorEntry(false);
            setShowSupplierModal(true);
        }
    }, [navVendorEntry])

    useEffect(() => {
        if (suppliersData?.length > 0) {
            const options = suppliersData.map((item: any) => (
                {
                    ...item,
                    label: item.supplierName,
                    value: item.supplierName,
                    key: parseInt(item.supplierId)
                }
            ))
            // const addNew = {
            //     label: "Add New",
            //     value: "addNew",
            //     key: "addNew"
            // }
            // options.unshift(addNew);
            if (props.showSupplier && typeof props.showSupplier !== "boolean" && !props.showAddNew) {
                options.shift();
            }
            setSupplierOptions(options);
        }
    }, [suppliersData]);

    useEffect(() => {
        if (supplier?.supplierId) {
            for (const key in supplier) {
                getValidateData(key, supplier[key])
            }
            setValues(supplier);
        } else if (supplierDetailsByAbnumber?.abNumber){
            for (const key in supplierDetailsByAbnumber) {
                getValidateData(key, supplierDetailsByAbnumber[key])
            }
            setValues({...supplierDetailsByAbnumber, telephone: supplierDetailsByAbnumber?.telephone?.replace(/\D/g, '')}); 
        }
    }, [supplier, supplierDetailsByAbnumber])

    useEffect(() => {
        if(supplier?.supplierId && !isOptionsSearched && secondaryAbnumberOptions?.length > 0){
            let list:any = [];
            if(secondaryAbnumberBySupplier?.length > 0){
                secondaryAbnumberBySupplier?.map((number: any)=>{
                    const find:any = secondaryAbnumberOptions?.find((item:any)=>item.abNumber == number?.secondaryNumber)
                    if(find?.abNumber && find.abNumber != supplier?.primaryAbNumber)
                        list.push(find);
                })
            }
            setAdditionalAbnoSelected(list)
        }
    },[secondaryAbnumberBySupplier, secondaryAbnumberOptions])

    useEffect(()=>{
        handlePrimaryAbNumberCheck(values.primaryAbNumber) 
        if(isNoAbNumber){
            setValues({
                ...values,
                'isSelfReported': true,
                'primaryAbNumber': null,
                'secondaryAbNumber': ""
            })
            setAdditionalAbnoSelected([])
        }
        else{
            setValues({
                ...values,
                'isSelfReported': undefined
            })
        }       
    },[isNoAbNumber])

    const handleClose = ()=>{
        navigate("/vendor-entry");
    }

    const hidePopup = () => {
        dispatchSetSupplier({});
        dispatchSetSupplierDetailsByAbnumber({})
        setIsAddNewAbnumber(false)
        setAdditionalAbnoSelected([])
        setValues({
            isActive: true
        })
        setShowSupplierModal(false);
        dispatchSetSupplierSavedFlag(false)
        setIsNew(false)
        if(props.hideSupplierModal) {
            props.hideSupplierModal();
        } else {
            handleClose();
        }
    }

    const closeSupplier = () => {
        if(props.hideSupplierModal) {
            props.hideSupplierModal();
            dispatchSetSupplierSavedFlag(false);
        }
    }

    const handleSave = () => {
        setSaveClicked(true); 
        for (const key in values) {
            getValidateData(key, values[key])
        }
        let error = Object.values(errors).filter((val:any) => val !== '');
        if (error?.length === 0) {
            if (props.showSupplier && !values.supplierId) {
                dispatchSetSupplierName(values.supplierName);
            }
            const payload = {
                ...values,
                IsActive: values.isActive,
                IsNoAbNumber: isNoAbNumber
            };
            if (payload.supplierId) {
                payload.modifiedBy = username;
            } else {
                payload.createdBy = username;
            }
            dispatchGetCreateUpdateSupplier(payload);
            setIsNew(false);
        }
    }

    const checkforName = (value: any) => {
        const istrue = suppliersData?.length && suppliersData.find((i: any) => i.supplierName.toLowerCase() === value.toLowerCase())
        if (istrue && !values.supplierId) {
            errors.supplierName='Name already exists'
        } else {
            errors.supplierName=''
        }
    }

    const handlePrimaryAbNumberCheck = (value: any) => {
        let err:any = JSON.parse(JSON.stringify(errors));
        if(!value){
            err.primaryAbNumber= isNoAbNumber ? '' : 'Please select Primary AbNumber'
        }else{
            err.primaryAbNumber=''
        }
        setErrors(err)
    }

    const handleNameCheck = (value: any) => {
        if(!value){
            errors.supplierName='Please provide Name'
        } else if (value?.length > 2 && value?.length < 250) {
            checkforName(value);
        } else if (value?.length >= 250) {
            errors.supplierName='Maximum 250 characters allowed'
        } else {
            errors.supplierName='Please provide Valid Name'
        }
    }

    const handleAddressLine1Check = (value: any) => {
        if(!value){
            errors.addressLine1='Please provide Address Line 1'
        }else if (value?.length >= 250) {
            errors.addressLine1= 'Maximum 250 characters allowed'    
        } else {
            errors.addressLine1=''
        }
    }

    const handleAddressLine2Check = (value: any) => {
        if (value?.length >= 250) {
            errors.addressLine2= 'Maximum 250 characters allowed'
        } else {
            errors.addressLine2=''
        }
    }

    const handleCityCheck = (value: any) => {
        if(!value){
            errors.city='Please provide City'
        }else if (value?.length >= 250) {
            errors.city= 'Maximum 250 characters allowed'
        } else { 
            errors.city=''
        }
    }

    const handleStateIdCheck = (value: any) => {
        if(!value){
            errors.stateId='Please provide State'
        }else{
            errors.stateId=''
        }
    }

    const handleCountryIdCheck = (value: any) => {
        if(!value){
            errors.countryId='Please provide Country'
        }else{
            errors.countryId=''
        }
    }

    const handleContactNameCheck = (value: any) => {
        if(!value){
            errors.contactName='Please provide Contact Name'  
        }else if (value?.length >= 250) {
            errors.contactName= 'Maximum 250 characters allowed'
        } else { 
            errors.contactName=''
        }
    }

    const handleTitleCheck = (value: any) => {
         if (value?.length >= 250) {
            errors.title= 'Maximum 250 characters allowed'
        } else {
            errors.title=''
        }
    }

    const validateEmail = (email: any) => {
        const at = email?.indexOf("@");
        const dot = email?.lastIndexOf("\.");
        return email?.length > 0 &&
          at > 0 &&
          dot > at + 1 &&
          dot < email?.length - 2 &&
          email[at + 1] !== "." &&
          email?.indexOf(" ") === -1 &&
          email?.indexOf("..") === -1;
      }

    const handleEmailCheck = (value: any) => {
        const isValid = validateEmail(value);
        if(!value){
            errors.email='Please provide Email'
        }else if (isValid) {
            errors.email=''
        } else {
            errors.email='Please provide valid Email'
        }
    }

    const handleTelephoneCheck = (value: any) => {
        const regex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        const isValid = regex.test(value);
        if(!value){
            errors.telephone='Please provide Telephone'
        }else if (isValid) {
            errors.telephone=''    
        } else {
            errors.telephone='Please provide valid Telephone'
        }
    }

    const handleManufacturerCheck = (value: any) => {
        if (value === null || value === undefined) {
            errors.manufacturer = "Please select Manufacturer"
        } else {
            errors.manufacturer = ""
        }
    }

    const handleIsSelfReportedCheck = (value: any) => {
        if (value === null || value === undefined) {
            errors.isSelfReported = "Please select Self Reported"
        } else {
            errors.isSelfReported = ""
        }
    }

    const handleAdditionalAbnumberCheck = (value: any) => {
        if (value?.length <= 0 || value === undefined) {
            errors.additionalAbnumber = "Please select Additional Abnumbers"
        } else {
            errors.additionalAbnumber = ""
        }
    }

    const handleCategoryManagerCheck = (value: any) => {        
        if (value === null || value === undefined) {
            errors.categoryManagerError = "Please select Category Manager"
        } else {
            errors.categoryManagerError = ""
        }
    }

    const getValidateData = (name: any, value: any) => {
        switch (name) {
            case 'supplierName':
                handleNameCheck(value);
                break;
            case 'addressLine1':
                handleAddressLine1Check(value);
                break;
            case 'addressLine2':
                handleAddressLine2Check(value);
                break;
            case 'city':
                handleCityCheck(value);
                break;
            case 'stateId':
                handleStateIdCheck(value);
                break;
            case 'countryId':
                handleCountryIdCheck(value);
                break;
            case 'contactName':
                handleContactNameCheck(value);
                break;
            case 'title':
                handleTitleCheck(value);
                break;
            case 'email':
                handleEmailCheck(value);
                break;
            case 'telephone':
                handleTelephoneCheck(value);
                break;
            case 'manufacturer':
                handleManufacturerCheck(value);
                break;
            case 'isSelfReported':
                handleIsSelfReportedCheck(value);
                break;
            case 'primaryAbNumber':
                handlePrimaryAbNumberCheck(value);
                break;
            case 'categoryManagerId':
                handleCategoryManagerCheck(value);
                break;
            default:
                break;
        }
    }
    
    const handleInputChange = (event: any, item: any) => {
        if (supplierSaved || errorSupplier) {
            handleMessage();
        }
        let name = event.name || event.target.getAttribute('name') || event.target.title;
        let value = item;
        if (name === "telephone") value = value.replace(/\D/g, '');
        if (name === "stateId" || name === "countryId") value = item.key;
        if (name === "isActive") value = item;
        if (name === "primaryAbNumber") {
            value = item.abNumber;
            onChangePrimaryAbnumber(value);
        }
        getValidateData(name, value);
        if (
            (name === "email" && value?.length > 150) ||
            ((name === "supplierName" || name === "addressLine1" || name === "addressLine2" || name === "city" || name === "contactName" || name === "title") && value.length > 250)
        ) 
        {
            return;
        }
        setValues({
            ...values,
            [name]: value
        })
    }
    const handleRadio = (_event: any, item: any) => {
        getValidateData(item?.name, item.value);
        if(item?.name == 'isSelfReported' && isNoAbNumber){
            setValues({
                ...values,
                [item?.name]: true
            })    
        }else{
            setValues({
                ...values,
                [item?.name]: item.value === "yes"
            })
        }
        
    }

    const handleCategoryManager = (selectedValueId : any) => {        
        getValidateData("categoryManagerId", selectedValueId);
        setValues({
            ...values,
            categoryManagerId: selectedValueId
        })
    }

    const handleAdditionalAbnumber = (selectedValueId : any) => {
        setAdditionalAbnoSelected(selectedValueId)
        const selectedAbnumber = selectedValueId?.map((item:any)=>item?.abNumber).join(",")  
        const selectedVendorName = selectedValueId?.map((item:any)=>item?.supplierName).join(",")    
        // getValidateData("additionalAbnumber", selectedAbnumber);
        setValues({
            ...values,
            secondaryAbNumber: selectedAbnumber,
            secondaryVendorName: selectedVendorName
        })
    }

    const getCheckboxLabel = () => {
        return (
            <div>
                Manufacturer<span className='required'>*</span>
            </div>
        )
    };

    const handleSupplier = (_event: any, item: any) => {
        if (supplierSaved || errorSupplier) {
            handleMessage();
        }
        if (item.key === "addNew") {
            setValues({
                isActive: true,
                countryId: countryOptions?.find((i: any) => i.value === 'United States')?.key
            })
            setIsNew(true);
            dispatchSetSupplier({})
            getInitialError();
            setSaveClicked(false);
        } else {
            setIsOptionsSearched(false)
            setAdditionalAbnoSelected([])
            dispatchGetSecondaryAbnumber({
                supplierId: item.key,
                searchParam: ""
            });
            dispatchGetSupplierById(item.key);
            dispatchGetSecondaryAbnumberBySupplier(item.key)
            setIsNew(false);
        }
    }

    const onClickAddNew = () => {
        setValues({
            isActive: true,
            countryId: countryOptions?.find((i: any) => i.value === 'United States')?.key
        })
        setIsOptionsSearched(false)
        dispatchGetSecondaryAbnumber({
            supplierId: 0,
            searchParam: ""
        });
        setIsNew(true);
        dispatchSetSupplier({})
        dispatchSetSupplierDetailsByAbnumber({})
        getInitialError();
        setSaveClicked(false);
        setIsAddNewAbnumber(true)
        setAdditionalAbnoSelected([])
        setIsNoAbNumber(false);
    }

    const onClickDeleteAddNew = () => {
        setValues({
            isActive: true
        })
        setIsNew(false);
        dispatchSetSupplier({})
        dispatchSetSupplierDetailsByAbnumber({})
        getInitialError();
        setSaveClicked(false);
        setIsAddNewAbnumber(false)
        setAdditionalAbnoSelected([])
        setIsNoAbNumber(false)
    }

    const breadcrumlist = [
        { 
          text: 'Rebate Management', 
          key: 'f1', 
          // href: '/Home', 
          // onClick: (e: any) => { 
          //     e.preventDefault(); handleHome() 
          // } 
        },
        { text: 'Vendor Entry', key: 'f2' }
    ]

    const handleMessage = () => {
        dispatchSetSupplierSavedFlag(false);
        dispatchSetErrorSupplier("")
    }

    const manufacturerOptions = [
        {key: "yes", value: "yes", text: "Yes", name: "manufacturer"},
        {key: "no", value: "no", text: "No", name: "manufacturer"}
    ]

    const getFooterPrimaryBtnTxt = () => {
        if (!isAddNewAbnumber) {
            return "Update"
        } else {
            return "Save"
        }
    }

    const getRadioSelectedKey = (fieldName: any) => {
        let fieldValue:any = {}
        if(fieldName == "manufacturer"){
            fieldValue = values.manufacturer; 
        }else if(fieldName == "isSelfReported"){
            fieldValue = values.isSelfReported
        }
        if (fieldValue) {
            return "yes";
        } else if (fieldValue === false) {
            return "no";
        } else {
            return "";
        }
    }

    const getValue = (name: any) => {
        if(values[name]) {
            return values[name];
        } else {
            return "";
        }
    }

    const getError = (name: any) => {
        if (!isSaveClicked) {
            return "";
        }
        if (errors[name]) {
            return errors[name];
        } else {
            return "";
        }
    }

    const getExternalError = (name: any) => {
        if (!isSaveClicked) {
            return "";
        }
        if (errors[name]) {
            return <div className="error-message">{errors[name]}</div>;
        } else {
            return "";
        }
    }
    const [authorized, setAuthorized] = useState<any>(null);
    useEffect(() => {
        const checkPermission = isAuthorized("Suppliers", "view");
        setAuthorized(checkPermission);
        
    }, []);

    if (!(authorized) && authorized != null) {
        return <ErrorHandling errorNumber='403'/>
    } 

    const onChangePrimaryAbnumber = (abNumber:any) => {
        setIsOptionsSearched(false)
        setAdditionalAbnoSelected([])
        dispatchGetSecondaryAbnumber({
            supplierId: 0,
            searchParam: ""
        });
        dispatchGetSupplierDetailsByAbnumber(abNumber)
    }

    const selfReportedOptions = [
        {key: "yes", text: "Yes", value:"yes", name: "isSelfReported"},
        {key: "no", text: "No", value:"no" , name: "isSelfReported"}
    ]

    return (
        <SupplierEntry 
            showSupplierModal={showSupplierModal}
            hidePopup={hidePopup}
            handleSave={handleSave}
            handleInputChange={handleInputChange}
            getCheckboxLabel={getCheckboxLabel}
            breadcrumlist={breadcrumlist}
            supplierOptions={supplierOptions}
            stateOptions={stateOptions}
            countryOptions={countryOptions}
            manufacturerOptions={manufacturerOptions}
            handleRadio={handleRadio}
            showSupplier={props.showSupplier}
            handleSupplier={handleSupplier}
            supplierSaved={supplierSaved}
            handleMessage={handleMessage}
            errorSupplier={errorSupplier}
            FooterPrimaryBtnTxt={getFooterPrimaryBtnTxt}
            getRadioSelectedKey={getRadioSelectedKey}
            getValue={getValue}
            getError={getError}
            getExternalError={getExternalError}
            isNew={isNew}
            categoryManagerOptions = {categoryManagerOptions}
            handleCategoryManager = {handleCategoryManager}
            primaryAbnumberOptions = {primaryAbnumberOptions}
            secondaryAbnumberOptions = {secondaryAbnumberOptions}
            AdditionalAbnoSelected = {AdditionalAbnoSelected}
            selfReportedOptions = {selfReportedOptions}
            onClickAddNew = {onClickAddNew}
            onClickDeleteAddNew = {onClickDeleteAddNew}
            isAddNewAbnumber = {isAddNewAbnumber}
            handleAdditionalAbnumber = {handleAdditionalAbnumber}
            primarySearchValue = {primarySearchValue}
            setPrimarySearchValue={setPrimarySearchValue}
            setSecondarySearchValue={setSecondarySearchValue}
            isLoadingPrimary={isLoadingPrimary}
            isLoadingSecondary={isLoadingSecondary}
            isNoAbNumber={isNoAbNumber}
            setIsNoAbNumber={setIsNoAbNumber}
        />)
}

export default SupplierEntryContainer;